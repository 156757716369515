import isObject from "./isObject";
import isString from "./isString";

export default function appendToFormData(data: any, nestedKey?: string) {
  return function(this: FormData) {
    Object.entries(data).forEach(([key, value]) => {
      const compoundKey = nestedKey ? `${nestedKey}[${key}]` : key;

      if (isObject(value)) {
        appendToFormData(value, compoundKey).call(this);
      } else if (isString(value) || value instanceof File || value instanceof Blob) {
        this.append(compoundKey, value);
      }
    });
  };
}