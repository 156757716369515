<template>
  <div data-component-name="Forms.GDPR">
    <USA
      v-if="countryISO === 'US'"
      :disabled
    />

    <GLOBAL
      v-else
      v-model="modelValue"
      :error
      :disabled
    />
  </div>
</template>

<script setup lang="ts">
import USA from './USA.vue';
import GLOBAL from './GLOBAL.vue';

const modelValue = defineModel('modelValue', {
  default: false,
});

withDefaults(
  defineProps<{
    countryISO?: string
    error?: string
    disabled?: boolean
  }>(),
  {
    disabled: false,
  },
);
</script>
