<template>
  <form
    data-component-name="Forms.RequestBundle"
    data-id="requestBundle"
    autocomplete="off"
    @submit="submitHandler"
  >
    <div class="step-one">
      <p class="title">
        <b>{{ $t('Step') }} 1:</b>

        {{ $t('help.download-update.step-one.title') }} -

        <NuxtLink
          :to="localePathWithSlash('/help/how-to-acquire-the-object-first-appliance-serial-number')"
          target="_blank"
        >
          {{ $t('help.download-update.step-two.link') }}?
        </NuxtLink>
      </p>

      <AppInputVerification
        v-model="serial_number"
        v-model:status="verificationStatus"
        :label="$t('Serial_Number')"
        :maxlength="17"
        :valid="isFieldValid('serial_number')"
        required
        @verify="verifySerialNumber"
      >
        <template v-if="errors.serial_number" #error>
          <span class="error">
            {{ errors.serial_number }}
          </span>
        </template>
      </AppInputVerification>
    </div>

    <div class="step-two">
      <p class="title">
        <b>{{ $t('Step') }} 2:</b>
        {{ $t('help.download-update.step-two.title') }}
      </p>

      <div class="inputs">
        <AppInput
          v-model="full_name"
          :label="$t('Full_Name')"
          :error="errors.full_name"
          :disabled="!isVerificationCompleted"
          required
          size="medium"
          name="full_name"
        />

        <AppInput
          v-model="corporate_email"
          :label="$t('Corporate_Email')"
          :error="errors.corporate_email"
          :disabled="!isVerificationCompleted"
          required
          size="medium"
          type="email"
          name="corporate_email"
        />

        <AppInput
          v-model="company"
          :label="$t('Company')"
          :error="errors.company"
          :disabled="!isVerificationCompleted"
          required
          size="medium"
          name="company"
        />

        <PhoneInput
          v-model="phone"
          ref="phoneInput"
          :error="errors.phone"
          :country-i-s-o
          :disabled="!isVerificationCompleted"
          required
          size="medium"
        />
      </div>

      <NuxtTurnstile
        v-model="token"
        ref="captcha"
        :options="{
          theme: 'light',
          language: locale,
        }"
      />

      <Forms.GDPR
        v-model="GDPR"
        :error="errors.GDPR"
        :country-i-s-o
        :disabled="!isVerificationCompleted"
      />

      <Buttons.Regular
        :disabled="!isFormValid"
        accent="purple"
        size="medium"
      >
        {{ $t('help.download-update.step-two.submit-button') }}
        <SvgArrowForward />
      </Buttons.Regular>
    </div>
  </form>
</template>

<script setup lang="ts">
// validation
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppInput from '~/components/redesign/AppInput.vue';
import PhoneInput from '~/components/redesign/PhoneInput.vue';
import AppInputVerification from '~/components/redesign/AppInputVerification.vue';
import Buttons from '~/components/redesign/Buttons';
import Forms from '~/components/redesign/Forms';

// composables
import { useI18n } from '#i18n';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// store
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/stores/common';

// utils
import get from 'lodash.get';
import omit from 'lodash.omit';
import isString from '~/utils/isString';

// constants
import { REGEX } from '~/constants';
import { STATUS } from '~/types/request';
import { SERIAL_NUMBER_EXIST, OFFLINE_BUNDLE } from '~/constants/api-endpoints';

// exceptions
import { FormValidationError } from '~/exceptions';

const emit = defineEmits<{
  loading: [value: boolean]
  submitted: []
}>();

const { t, locale } = useI18n();
const { ipInfo } = storeToRefs(useCommonStore());
const localePathWithSlash = useLocalePathWithSlash();
const { public: { api_v2: baseURL } } = useRuntimeConfig();

const captcha = useTemplateRef<{ reset(): void }>('captcha');
const phoneInput = useTemplateRef<{ isValid(): boolean }>('phoneInput');

const token = ref<string>();
const verificationStatus = ref<STATUS>(STATUS.IDLE);

const countryISO = computed(() => get(ipInfo.value, 'iso_code'));
const isUSA = computed(() => countryISO.value === 'US');
const isVerificationCompleted = computed(() => verificationStatus.value === STATUS.FULFILLED);
const isFormValid = computed(() =>
  meta.value.valid &&
  isVerificationCompleted.value &&
  isString(token.value),
);

const validationSchema = toTypedSchema(yup.object({
  serial_number: yup
    .string()
    .trim()
    .test(
      'not-allowed-letters',
      t('Validations.invalid-serial-number'),
      value => !value || REGEX.SERIAL_NUMBER.test(value),
    )
    .min(13, t('Validations.min', { length: 13 }))
    .max(17, t('Validations.max', { length: 17 }))
    .required(),

  full_name: yup
    .string()
    .trim()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .required(t('Validations.This_field_is_required')),

  corporate_email: yup
    .string()
    .trim()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
    .companyEmail()
    .email()
    .required(t('Validations.This_field_is_required')),

  company: yup
    .string()
    .trim()
    .max(128, ({ max }) => t('Validations.max', { length: max }))
    .required(t('Validations.This_field_is_required')),

  phone: yup
    .string()
    .trim()
    .test({
      message: t('Validations.Invalid_phone'),
      test: () => phoneInput.value?.isValid(),
    })
    .required(t('Validations.This_field_is_required'))
    .default(''),

  GDPR: yup
    .boolean()
    .when({
      is: () => isUSA.value,
      then: schema => schema.notRequired(),
      otherwise: schema => schema
        .oneOf([true], t('Validations.This_field_is_required'))
        .required(t('Validations.This_field_is_required')),
    }),
}));

const {
  meta,
  errors,
  defineField,
  isFieldValid,
  handleSubmit,
  setErrors,
} = useForm({ validationSchema });

const [serial_number] = defineField('serial_number');
const [full_name] = defineField('full_name');
const [corporate_email] = defineField('corporate_email');
const [company] = defineField('company');
const [phone] = defineField('phone');
const [GDPR] = defineField('GDPR');

const verifySerialNumber = async () => {
  const setRejected = () => {
    verificationStatus.value = STATUS.REJECTED;
    setErrors({
      serial_number: t('help.download-update.step-one.verification-error'),
    });
  };

  try {
    verificationStatus.value = STATUS.PENDING;

    const { success } = await $fetch<{ success: boolean }>(
      SERIAL_NUMBER_EXIST(serial_number.value),
      { baseURL },
    );

    if (success) {
      verificationStatus.value = STATUS.FULFILLED;
    } else {
      setRejected();
    }
  } catch {
    setRejected();
  }
};

const submitHandler = handleSubmit(async values => {
  try {
    emit('loading', true);

    const requestBody = {
      ...omit(values, ['GDPR']),
      cloudflare_turnstile_token: token.value,
    };

    await $fetch<{ success: boolean }>(OFFLINE_BUNDLE, {
      method: 'POST',
      baseURL,
      body: requestBody,
    });

    emit('submitted');
  } catch (error) {
    token.value = undefined;
    captcha.value?.reset();

    if (error instanceof FormValidationError) {
      setErrors(error.fields);
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/components";

[data-component-name="Forms.RequestBundle"] {
  .step-one,
  .step-two {
    p.title {
      @include body-3;
      margin-bottom: 1.5rem;

      a {
        @include app-link;
      }
    }
  }

  .step-one {
    margin-bottom: 1.9rem;

    [data-component-name="AppInputVerification"] {
      span.error {
        @include caption;
        color: token('error');

        display: block;
        margin: 0.25rem 0 0 1rem;
      }
    }
  }

  .step-two {
    .inputs {
      margin-bottom: 1.8rem;

      > *:not(:last-child) {
        margin-bottom: 1.4rem;
      }
    }


    [data-component-name="Forms.GDPR"] {
      margin: 1.65rem 0 0.5rem 0;
    }

    [data-component-name="Buttons.Regular"] {
      width: 100%;
    }
  }
}
</style>
