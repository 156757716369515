<template>
  <div data-component-name="PhoneInput">
    <label>
      {{ $t('Phone') }} {{ (required ? '*' : '') }}
    </label>

    <VueTelInput
      v-model="internalValue"
      ref="vueTelInputRef"
      :auto-default-country="false"
      :default-country="countryISO"
      :input-options="{
        name: 'phone',
        showDialCode: false,
        placeholder: $t('Enter_a_phone_number'),
        styleClasses: { error },
      }"
      :dropdown-options="{
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showSearchBox: true,
        showFlags: true,
        searchBoxPlaceholder: $t('Search_by_country_name'),
      }"
      :style-classes="[size, fill]"
      :disabled
      mode="national"
      :id="`phoneInput-${id}`"
      @on-input="inputHandler"
      valid-characters-only
    >
      <template #arrow-icon>
        <button type="button">
          <SvgArrowDownFill />
        </button>
      </template>
    </VueTelInput>

    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script setup lang="ts">
// https://iamstevendao.com/vue-tel-input/

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import get from 'lodash.get';

type VueTelInputRef = {
  choose(value: string): void
  phoneObject: {
    country?: {
      areaCodes: null
      dialCode: string
      iso2: string
      name: string
      priority: number
    },
    number: string
    valid?: boolean
    formatted: string
  },
};

const props = withDefaults(
  defineProps<{
    modelValue: string
    required?: boolean
    disabled?: boolean
    error?: string
    countryISO?: string
    size?: 'medium' | 'large'
    fill?: 'solid' | 'outline'
  }>(),
  {
    required: false,
    disabled: false,
    countryISO: 'US',
    size: 'large',
    fill: 'solid',
  },
);
const emit = defineEmits<{ 'update:modelValue': [value: string] }>();

const id = useId();
const internalValue = ref(props.modelValue || '');

const vueTelInputRef = ref<null | VueTelInputRef>(null);

const inputHandler = (value: string) => {
  if (value === props.modelValue) return;

  emit(
    'update:modelValue',
    get(vueTelInputRef.value, 'phoneObject.number', value),
  );
};

watch(toRef(props, 'countryISO'), value => {
  if (!vueTelInputRef.value) return;
  vueTelInputRef.value.choose(value);
});

defineExpose({
  isValid: () => vueTelInputRef.value?.phoneObject.valid && /\d$/.test(internalValue.value),
});
</script>

<style scoped lang="scss">
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/functions/token";
@import "$/variables/shadows";

[data-component-name="PhoneInput"] {
  position: relative;
  width: 100%;

  label {
    @include caption;
    padding: 0 0.25rem;
    background-color: token('surf-cont-primary');

    position: absolute;
    left: 1.05rem;
    transform: translateY(-50%);
    z-index: 1;
  }

  &:deep(.vue-tel-input) {
    border: unset;
    border-radius: unset;
    box-shadow: unset;
    position: relative;
    transition: all 0.15s ease;

    .vti__dropdown {
      position: static;
      padding: unset;
      background-color: unset;

      &.open {
        .vti__selection {
          button {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .vti__selection {
        @include flex-center-center;
        gap: 0.25rem;

        transition: background-color 0.15s ease;

        .vti__flag {
          margin: 0 2px;
        }

        .vti__country-code {
          @include body-3;
          color: token('text-primary');
          user-select: none;
        }

        button {
          transform: translateY(1px);

          svg {
            transition: transform 0.15s ease;
          }
        }

        &:active {
          background-color: rgba(token('accent-selected'), 8%);
        }
      }

      .vti__dropdown-list {
        border: unset;

        position: absolute;
        left: 0;
        right: 0;
        width: 100%;

        box-shadow: map-get($shadows, 'Elevation 1');

        &.above {
          bottom: calc(100% + 0.5rem);
        }

        &.below {
          top: calc(100% + 0.25rem);
        }

        input.vti__search_box {
          border-radius: unset;
          width: 100%;

          padding: 0.75rem 1rem;
          margin: 0;

          transition: box-shadow 0.15s ease;

          @include body-3;

          color: token('text-primary');
          caret-color: token('outline-action');

          &:focus {
            box-shadow: inset 0 0 0 1px token('outline-action');
          }

          &::placeholder {
            color: token('text-secondary');
          }
        }

        .vti__dropdown-item {
          padding: 0.75rem 1rem;
          transition: background-color 0.15s ease;

          @include flex-center-start;
          gap: 0.25rem;

          &.highlighted {
            background-color: unset;
          }

          .vti__flag {}

          strong {
            @include body-3;
          }

          span:not(.vti__flag) {
            color: token('text-secondary');
            @include body-3;
          }

          &:hover {
            background-color: rgba(token('accent-hover'), 16%);
          }

          &:active {
            background-color: rgba(token('accent-hover'), 24%);
          }
        }
      }
    }

    > input.vti__input {
      border-radius: unset;
      background-color: unset;
      caret-color: token('outline-action');

      @include body-3;

      &::placeholder {
        color: token('text-secondary');
      }
    }
  }

  // SIZES
  &:deep(.large) {
    .vti__selection {
      padding: 1rem 0.5rem 1rem 1.0625rem;
    }

    .vti__input {
      padding: 1rem 1rem 1rem 0.5rem;
    }
  }

  &:deep(.medium) {
    .vti__selection {
      padding: 0.8125rem 0.5rem 0.8125rem 1.0625rem;
    }

    .vti__input {
      padding: 0.8125rem 1.56rem 0.8125rem 0.5rem;
    }
  }

  // STATES
  &:has(.vti__input.error) {
    &:deep(.vue-tel-input) {
      box-shadow: inset 0 0 0 1px token('error');
    }

    span.error {
      @include caption;
      color: token('error');
      margin: 0.25rem 0 0 1rem;
      display: block;
    }
  }

  &:has(.vti__input:focus) {
    &:deep(.vue-tel-input) {
      box-shadow: inset 0 0 0 1px token('outline-action');
    }
  }

  &:has(.vue-tel-input:hover) {
    &:deep(.vue-tel-input) {
      background-color: rgba(token('accent-selected'), 16%);
    }
  }

  &:has(.vue-tel-input.disabled) {
    label {
      color: token('text-tertiary');
    }

    &:deep(.vue-tel-input) {
      background-color: token('neutral-disabled');

      .vti__dropdown {
        .vti__selection {
          cursor: not-allowed;

          &:active {
            background-color: unset;
          }

          .vti__flag {
            opacity: 20%;
            filter: grayscale(100%);
          }

          .vti__country-code {
            color: token('text-tertiary');
          }

          button {
            cursor: inherit;

            svg {
              opacity: 20%;
            }
          }
        }
      }

      .vti__input {
        cursor: not-allowed;
        color: token('text-tertiary');

        &::placeholder {
          color: token('text-tertiary');
        }
      }
    }
  }

  // FILL
  &:deep(.solid) {
    background-color: token('surf-cont-secondary');
  }

  &:deep(.outline) {
    box-shadow: inset 0 0 0 1px token('outline-secondary');

    .vti__selection {
      position: relative;

      &:before {
        content: '';
        display: block;

        position: absolute;
        right: 0;
        bottom: 0;
        width: 1px;
        height: 100%;

        background-color: token('outline-secondary');
      }
    }
  }
}
</style>
