<script lang="ts" setup>
// validation
import * as yup from 'yup';
import { toTypedSchema } from '@vee-validate/yup';

// components
import AppDropdown from '~/components/redesign/AppDropdown';
import AppInput from '~/components/redesign/AppInput.vue';
import AppInputFile from '~/components/redesign/AppInputFile.vue';
import AppRadioGroup from '~/components/redesign/AppRadioGroup.vue';
import AppTextarea from '~/components/redesign/AppTextarea.vue';
import Buttons from '~/components/redesign/Buttons';
import Forms from '~/components/redesign/Forms';
import PhoneInput from '~/components/redesign/PhoneInput.vue';

// services
import Cookie from '~/services/Cookie';
import { getOsanoConsent } from '~/services/OsanoConsentData';

// stores
import { useCommonStore } from '~/stores/common';

// composables
import { useI18n } from '#i18n';
import { useForm } from 'vee-validate';
import { storeToRefs } from 'pinia';

// utils
import omit from 'lodash.omit';
import pick from 'lodash.pick';
import has from 'lodash.has';
import appendToFormData from '~/utils/appendToFormData';

// services
import { submittedCookieValue } from '~/services/FormFillComponent';

// constants
import { REGEX, MIME_TYPES, API_ENDPOINTS } from '~/constants';

// JSON
import countries from '~/data/countries.json';

// types
import type {
  Countries,
  Country,
  CountryWithStates,
  State,
} from '~/types/country';
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';

// exceptions
import { FormValidationError } from '~/exceptions';

const props = defineProps<{
  btnText: string;
  accent?: BaseButtonProps['accent'];
  applicationId: string;
  defaultPosition: string;
}>();

const emit = defineEmits<{
  submitted: [];
  loading: [value: boolean];
}>();

const { t } = useI18n();

const HAS_WORK_PERMIT_OPTION = {
  YES: t('yes'),
  NO: t('no'),
} as Record<string, string>;

const HAS_WORK_PERMIT_OPTION_KEYS = Object.keys(HAS_WORK_PERMIT_OPTION);

const ALLOWED_FILE_TYPES = [
  MIME_TYPES.PDF,
  MIME_TYPES.MS_WORD,
  MIME_TYPES.WORD_DOCUMENT,
  MIME_TYPES.PLAIN_TEXT,
  MIME_TYPES.RTF,
];

const { ipInfo } = storeToRefs(useCommonStore());
const phoneInputRef = ref<null | { isValid(): boolean }>(null);

const validationSchema = toTypedSchema(
  yup.object({
    fullname: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required')),

    email: yup
      .string()
      .trim()
      .max(128, ({ max }) => t('Validations.max', { length: max }))
      .matches(REGEX.EMAIL, t('Validations.Invalid_email_address'))
      .email()
      .required(t('Validations.This_field_is_required')),

    position: yup
      .string()
      .required(t('Validations.This_field_is_required'))
      .default(props.defaultPosition),

    country: yup
      .object()
      .default({ active: true, label: 'United States', isoCode: 'US' })
      .required(t('Validations.This_field_is_required')),

    state: yup
      .object()
      .default({ active: true, label: 'Massachusetts', isoCode: 'MA' })
      .when('country', {
        is: (country?: Country) =>
          ['CA', 'US'].includes(country?.isoCode || ''),
        then: schema =>
          schema.required(t('Validations.This_field_is_required')),
        otherwise: schema => schema.notRequired(),
      }),

    city: yup
      .string()
      .max(64, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required')),

    phone: yup
      .string()
      .trim()
      .test({
        message: t('Validations.Invalid_phone'),
        test: () => phoneInputRef.value?.isValid(),
      })
      .required(t('Validations.This_field_is_required'))
      .default(''),

    has_work_permit: yup
      .string()
      .oneOf(HAS_WORK_PERMIT_OPTION_KEYS)
      .required(t('Validations.This_field_is_required')),

    cv: yup
      .mixed()
      .required(t('Validations.file_is_required'))
      .test('fileType', t('Validations.invalid_file_type'), value => {
        if (!value) return false;

        return ALLOWED_FILE_TYPES.includes(value.type);
      })
      .test('fileSize', t('Validations.invalid_file_size'), value => {
        if (!value) return false;
        const maxSize = 10 * 1024 * 1024;
        return value.size <= maxSize;
      }),

    covering_letter: yup
      .string()
      .trim()
      .max(300, ({ max }) => t('Validations.max', { length: max }))
      .required(t('Validations.This_field_is_required')),

    GDPR: yup.boolean().when('country', {
      is: (value?: Country) => value?.isoCode === 'US',
      then: schema => schema.notRequired(),
      otherwise: schema =>
        schema
          .oneOf([true], t('Validations.This_field_is_required'))
          .required(t('Validations.This_field_is_required')),
    }),
  }),
);

const {
  errors,
  defineField,
  handleSubmit,
  resetForm,
  meta,
  setFieldValue,
  resetField,
  setErrors,
} = useForm({ validationSchema });

const [fullname] = defineField('fullname');
const [email] = defineField('email');
const [position] = defineField('position');
const [country] = defineField('country');
const [state] = defineField('state');
const [city] = defineField('city');
const [phone] = defineField('phone');
const [has_work_permit] = defineField('has_work_permit');
const [covering_letter] = defineField('covering_letter');
const [GDPR] = defineField('GDPR');
defineField('cv');

const activeCountries = computed(() =>
  (countries as Countries).filter(country => country.active),
);

const isUSA = computed(() => country.value?.isoCode === 'US');
const isCanada = computed(() => country.value?.isoCode === 'CA');

const countryHasStates = computed(
  () => has(country.value, 'states'),
);

const activeStates = computed(() =>
  countryHasStates.value
    ? (country.value as CountryWithStates).states.filter(
        state => state.active,
      )
    : [],
);

watch(
  ipInfo,
  info => {
    if (!info) return;
    const foundCountry = (countries as Countries).find(
      country => country.isoCode === info.iso_code,
    );

    if (foundCountry) {
      setFieldValue('country', foundCountry);

      let foundState: State | undefined;

      if ('states' in foundCountry) {
        foundState = foundCountry.states.find(
          item => item.isoCode === info.state,
        );
      }

      setFieldValue('state', foundState || null);
    }

    if (info.city) {
      setFieldValue('city', info.city);
    }
  },
  { immediate: true },
);

watch(country, (newCountry, oldCountry) => {
  if (newCountry.label === oldCountry.label) return;
  resetField('state', { value: null });
});

onMounted(() => setFieldValue('has_work_permit', submittedCookieValue('has_work_permit') ?? HAS_WORK_PERMIT_OPTION_KEYS[0]));

const formFillingStart = ref<null | number>(null);
const resetFormFillingStart = () => {
  formFillingStart.value = null;
};
const setFormFillingStart = () => {
  if (formFillingStart.value !== null) return;
  formFillingStart.value = Date.now();
};
const getFormFillingSeconds = (): number | undefined => {
  if (formFillingStart.value === null) return;
  return (Date.now() - formFillingStart.value) / 1000;
};

const handleFileInput = event => {
  setFieldValue('cv', event);
}

const submitHandler = handleSubmit(async values => {
  const [firstname, lastname] = values.fullname.split(' ');

  const requestBody = {
    ...omit(values, 'fullname', 'GDPR'),

    firstname,
    lastname: lastname || firstname,

    country: values.country.label,
    state: values.state?.label,

    start_time: formFillingStart.value?.toString(),
    of_form_duration: getFormFillingSeconds()?.toString(),

    href: window.location.href,
    cookie: Cookie.getCookieArray(),

    entry_page: Cookie.get('EntryPage') || '',
    referrer_page: Cookie.get('RefererPage') || '',

    consent: getOsanoConsent(),
  };

  const formData = new FormData();

  appendToFormData(requestBody).call(formData);

  resetFormFillingStart();

  emit('loading', true);

  const {
    public: { api_v2: baseURL },
  } = useRuntimeConfig();

  try {
    await $fetch(
      API_ENDPOINTS.JOB_POSTS_APPLY(props.applicationId),
      {
        baseURL,
        method: 'POST',
        body: formData,
      },
    );

    Cookie.clearAfterSubmit();

    resetForm({ values: pick(values, ['country', 'state', 'city']) });

    emit('submitted');
  } catch (error) {
    if (error instanceof FormValidationError) {
      setErrors(omit(error.fields, 'user_id'));
    }
  } finally {
    emit('loading', false);
  }
});
</script>

<template>
  <form
    data-component-name="Forms.ApplyRole"
    autocomplete="off"
    data-id="ApplyRole"
    @input.passive="setFormFillingStart"
    @change.passive="setFormFillingStart"
    @submit="submitHandler"
  >
    <AppInput
      v-model.trim="fullname"
      :label="$t('Full_Name')"
      :error="errors.fullname"
      required
      type="text"
      name="fullname"
    />

    <AppInput
      v-model="email"
      :label="$t('Email')"
      :error="errors.email"
      required
      type="email"
      name="email"
    />

    <AppInput
      v-model="position"
      :label="$t('Position')"
      :error="errors.position"
      required
      disabled
      type="text"
      name="position"
    />

    <AppDropdown.Base
      v-model="country"
      :options="activeCountries"
      :label="$t('Country')"
      :search-placeholder="$t('Search_by_country_name')"
      :error="errors.country"
      searchable
      required
      key-attribute="label"
      fill="solid"
    />

    <AppDropdown.Base
      v-if="isUSA || isCanada"
      v-model="state"
      :options="activeStates"
      :label="$t('State')"
      :search-placeholder="$t('Search_by_state_name')"
      :error="errors.state"
      searchable
      required
      key-attribute="label"
      fill="solid"
    />

    <AppInput
      v-model="city"
      :label="$t('City')"
      :error="errors.city"
      required
      type="text"
      name="city"
    />

    <PhoneInput
      v-model="phone"
      ref="phoneInputRef"
      :error="errors.phone"
      :country-i-s-o="country.isoCode"
      required
      fill="solid"
    />

    <AppRadioGroup
      v-model="has_work_permit"
      v-slot="{ value }"
      :options="HAS_WORK_PERMIT_OPTION_KEYS"
      :label="$t('Forms.ApplyRole.has-work-permit.label') + '*'"
      :error="errors.has_work_permit"
      name="has_work_permit"
      id="has_work_permit"
    >
      {{ HAS_WORK_PERMIT_OPTION[value] }}
    </AppRadioGroup>

    <AppInputFile
      :label="$t('Forms.ApplyRole.cv.label')"
      :placeholder="$t('Forms.ApplyRole.cv.placeholder')"
      :error="errors.cv"
      required
      accept=".pdf,.doc,.docx,.txt,.rtf,.mp4"
      @change="handleFileInput"
    />

    <AppTextarea
      v-model="covering_letter"
      :placeholder="$t('Forms.ApplyRole.cover-letter.placeholder') + ' *'"
      :error="errors.covering_letter"
      name="covering_letter"
    />

    <Forms.GDPR
      v-model="GDPR"
      :error="errors.GDPR"
      :country-i-s-o="country.isoCode"
    />

    <Buttons.Regular
      :disabled="!meta.valid"
      accent="purple"
      data-id="submit-GetFreeDemo"
    >
      {{ t("Submit") }}
      <SvgArrowForward />
    </Buttons.Regular>
  </form>
</template>

<style scoped lang="scss">
@use "_/mixins/size";
@use "_/mixins/flex";
@use "_/mixins/typo";
@use "_/mixins/media";
@use "_/fn";

[data-component-name="Forms.ApplyRole"] {
  @include flex.start-start;
  flex-direction: column;
  gap: 1rem;

  p.title {
    @include typo.body-3;
  }

  .terms {
    @include flex.start-start;
    gap: 0.25rem;
    margin-top: 0.5rem;

    p {
      @include typo.caption;

      label {
        display: block;
        margin-bottom: 0.5rem;
        cursor: pointer;

        &.error {
          @include typo.caption;
          color: fn.token("error");
        }
      }

      a {
        color: fn.token("link");
        text-decoration: underline;
      }
    }
  }

  &:deep([data-component-name="AppDropdown"]) {
    &:not(.opened) {
      .fake-input {
        border-color: fn.token("outline-secondary");

        &:not(:hover) {
          background-color: transparent;
        }
      }
    }
  }

  [data-component-name="Buttons.Regular"] {
    align-self: flex-end;

    @include media.mobile {
      align-self: stretch;
    }
  }
}
</style>
